import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Alert, Progress } from "components";
import { VERIFICATION_STATUS } from "constants/wizard";
import { cloneDeep, isNil } from "lodash";
import { useEffect, useState } from "react";

const buttonContainerStyles = (theme) => {
  return {
    marginTop: "10px",
    display: "flex",
    justifyContent: "flex-end",
    "& .backbutton": {
      marginRight: "14px",
    },
    "& .otherbutton": {
      marginRight: "14px",
    },
    "& button": {
      width: "200px",
      [theme.breakpoints.down("sm")]: {
        width: "unset",
      },
    },
  };
};

const greyTableWrapperStyles = (theme) => {
  return {
    width: "100%",
    backgroundColor: "#E5E6E7",
    borderRadius: "5px",
    padding: "20px",
    margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
      padding: "12px",
    },
  };
};

export const FieldWrapper = ({ label, children }) => {
  return (
    <Box>
      <Typography variant="subtitle1">{label}</Typography>
      <Box ml={2}>{children}</Box>
    </Box>
  );
};

export const ButtonContainer = ({ children }) => {
  return <Box sx={(theme) => buttonContainerStyles(theme)}>{children}</Box>;
};

export const GreyTableWrapper = ({ children }) => {
  return <Box sx={(theme) => greyTableWrapperStyles(theme)}>{children}</Box>;
};

const tableBorderStyles = () => {
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .border": {
      width: "90%",
      borderBottom: "3px solid #C9C9C9",
    },
  };
};

const tableRowStyles = (theme) => {
  return {
    display: "flex",
    alignItems: "center",
    marginBottom: "4px",
    "& .field1": {
      minWidth: "140px",
      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        minWidth: "80px",
        maxWidth: "80px",
      },
    },
    "& .field2": {
      minWidth: "220px",
      textTransform: "capitalize",
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        minWidth: "70px",
        maxWidth: "70px",
      },
    },
    "& .field3": {
      minWidth: "140px",
      textTransform: "capitalize",
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        minWidth: "70px",
        maxWidth: "70px",
      },
    },
    "& .field-cell": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  };
};

export const TableRow = ({
  field1,
  field2,
  field3,
  header = false,
  breakBefore = false,
}) => {
  const convert = (value) => {
    if (isNil(value)) return;
    return String(value).replace("_", " ");
  };
  return (
    <>
      {breakBefore && (
        <Box sx={tableBorderStyles} my={1}>
          <Box className="border"></Box>
        </Box>
      )}
      <Box sx={(theme) => tableRowStyles(theme)}>
        <Box className="field1">
          <Typography
            variant="subtitle1"
            fontWeight={header ? "400" : "600"}
            className="field-cell"
            lineHeight={{ xs: 1, sm: 1.5 }}
          >
            {convert(field1)}
          </Typography>
        </Box>
        <Box className="field2">
          <Typography
            variant="subtitle1"
            fontWeight={header ? "600" : "400"}
            className="field-cell"
          >
            {convert(field2)}
          </Typography>
        </Box>
        {!isNil(field3) && (
          <Box className="field3">
            <Typography
              variant="subtitle1"
              fontWeight={header ? "600" : "400"}
              className="field-cell"
            >
              {convert(field3)}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export const StepSummary = ({ children }) => (
  <Typography variant="body1">{children}</Typography>
);

export const VerificationStatusAction = ({
  severity,
  message,
  next_steps = [],
  note = "",
  type = "error",
  onClickBack = undefined,
  onClickCheckAgain = undefined,
  onClickStartAutoTest = undefined,
  children,
  system_checks = [],
  system_message = "",
  actionLabel = "",
  onClickAction = undefined,
  checkLoading = false,
  startTestButtonText = "Start Test"
}) => {
  return (
    <Box>
      <Alert
        severity={severity}
        message={message}
        next_steps={next_steps}
        note={note}
        system_checks={system_checks}
        system_message={system_message}
        actionLabel={actionLabel}
        onClickAction={onClickAction}
      />
      {children}
      <Box my={{ xs: 1, sm: 2 }}>
        <ButtonContainer>
          {onClickBack && (
            <Button
              onClick={onClickBack}
              className="backbutton"
              variant="outlined"
            >
              Back
            </Button>
          )}
          {type === "error" && onClickCheckAgain && (
            <LoadingButton
              onClick={onClickCheckAgain}
              className="otherbutton"
              variant="outlined"
              loading={checkLoading}
            >
              Check Again
            </LoadingButton>
          )}
          {onClickStartAutoTest && (
            <Button
              onClick={onClickStartAutoTest}
              variant="contained"
              disabled={type === "error"}
            >
              {startTestButtonText}
            </Button>
          )}
        </ButtonContainer>
      </Box>
    </Box>
  );
};

const stepProgressStyles = () => {
  return {
    "& .error-message": {
      color: "#91201B",
    },
  };
};

export const StepProgress = ({
  progress,
  children,
  onClickContinue = undefined,
  onClickBack = undefined,
  onClickRepeat = undefined,
  onClickPostpone = undefined,
  status,
  tableData = [],
  postpone_message = "",
  repeatTestButtonText = "Repeat Test"
}) => {
  const [messages, setMessages] = useState([]);
  const [skip, setSkip] = useState(false);

  useEffect(() => {
    if (progress) {
      setMessages(progress?.messages || []);
    }
  }, [progress]);

  return (
    <Box mb={4} mr={1} sx={stepProgressStyles}>
      {messages.map((item, i) => (
        <Typography
          key={i}
          variant="subtitle1"
          className={`${item.key === "fail" ? "error-message" : ""}`}
        >
          {item.message}
        </Typography>
      ))}
      {status === VERIFICATION_STATUS.inProgress && (
        <CircularProgress size={20} />
      )}
      {progress && (
        <Progress
          value={progress?.completed}
          total={progress?.total}
          unit={progress?.units}
          error={status === VERIFICATION_STATUS.failed}
        />
      )}
      {children}
      {tableData.length > 0 && (
        <GreyTableWrapper>
          {tableData.map((it, i) => (
            <TableRow
              key={i}
              field1={it?.field1}
              field2={it?.field2}
              field3={it?.field3}
              header={it?.header}
              breakBefore={it?.breakBefore}
            />
          ))}
        </GreyTableWrapper>
      )}

      {postpone_message && status !== VERIFICATION_STATUS.completed && (
        <Box my={1}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="skip"
                checked={skip}
                onChange={(e) => setSkip(e.target.checked)}
              />
            }
            label={postpone_message}
          />
        </Box>
      )}

      <Box my={{ xs: 1, sm: 2 }}>
        <ButtonContainer>
          <Button
            onClick={onClickBack}
            className="backbutton"
            variant="outlined"
          >
            Back
          </Button>
          {status !== VERIFICATION_STATUS.completed && (
            <Button
              onClick={onClickRepeat}
              disabled={status === VERIFICATION_STATUS.inProgress}
              className="otherbutton"
              variant="outlined"
            >
              {repeatTestButtonText}
            </Button>
          )}
          {!postpone_message && (
            <Button
              onClick={onClickContinue}
              disabled={status !== VERIFICATION_STATUS.completed}
              variant="contained"
            >
              Continue
            </Button>
          )}
          {postpone_message &&
            onClickPostpone &&
            status !== VERIFICATION_STATUS.completed && (
              <Button
                onClick={onClickPostpone}
                disabled={!skip}
                variant="contained"
              >
                Postpone Test
              </Button>
            )}
        </ButtonContainer>
      </Box>
    </Box>
  );
};

export const Checklist = ({
  checklist = [],
  tableData = [],
  onClickContinue = undefined,
  onClickBack = undefined,
  onUpdateChecklist = undefined,
  onHoldValues = undefined,
  onClickCommissioning = undefined,
  onClickCalibration = undefined,
  status,
  disableCommissioning = false,
  disableCalibration = false,
}) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(checklist);
  }, [checklist]);

  const onChange = (e, item) => {
    const cList = cloneDeep(list);
    const index = cList.findIndex((it) => it.key === item.key);
    cList[index].completed = !cList[index].completed;
    setList(cList);
    onUpdateChecklist(cList);
  };

  return (
    <Box mb={{ xs: 2, sm: 4 }}>
      {tableData.length > 0 && (
        <>
          {onHoldValues && status !== VERIFICATION_STATUS.completed && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "12px",
              }}
            >
              <Button
                onClick={onHoldValues}
                variant="outlined"
                startIcon={<Add />}
              >
                HOLD VALUES
              </Button>
            </Box>
          )}
          <GreyTableWrapper>
            {tableData.map((it, i) => (
              <TableRow
                key={i}
                field1={it?.field1}
                field2={it?.field2}
                field3={it?.field3}
                header={it?.header}
                breakBefore={it?.breakBefore}
              />
            ))}
          </GreyTableWrapper>
        </>
      )}
      {list.length > 0 && (
        <FormGroup>
          {list.map((item, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                marginBottom: "12px",
              }}
            >
              <FormControlLabel
                key={i}
                control={<Checkbox checked={item.completed} />}
                onChange={(e) => onChange(e, item)}
                label={item?.message}
                disabled={status === VERIFICATION_STATUS.completed}
              />
            </Box>
          ))}
        </FormGroup>
      )}
      <Box my={{ xs: 1, sm: 2 }}>
        <ButtonContainer>
          {onClickBack && (
            <Button
              onClick={onClickBack}
              className="backbutton"
              variant="outlined"
            >
              Back
            </Button>
          )}

          {onClickContinue && (
            <Button
              onClick={onClickContinue}
              disabled={list.some((x) => !x.completed)}
              variant="contained"
            >
              Continue
            </Button>
          )}
          {onClickCommissioning && (
            <Button
              onClick={onClickCommissioning}
              disabled={disableCommissioning}
              variant="contained"
            >
              Commission Unit
            </Button>
          )}

          {onClickCalibration && (
            <Button
              onClick={onClickCalibration}
              disabled={disableCalibration}
              variant="contained"
            >
              Start Calibration
            </Button>
          )}
        </ButtonContainer>
      </Box>
    </Box>
  );
};
